import React, { useContext, useState } from "react";
import { TitleRegister } from "../Pets.styled";
import { UploadImage } from "../../../UploadFiles/UploadImage";
import { useForm } from "react-hook-form";
import { useSnackBars } from "../../../../contexts/Snackbar/SnackbarContext";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import CurrentUserContext from "../../../../contexts/CurrentUserContext";
import { PetService } from "../../../../services/PetsService";
import { useParams } from "react-router-dom";
import { TextFieldBOValidate } from "../../../TextFieldBO/TextFieldBOValidate";
import { Box, IconButton, Typography } from "@mui/material";
import { getDateToString } from "../../../../utils/functions";
import { useQueryClient } from "react-query";
import {
  defaultDescriptionPets,
  namesUseQuery,
} from "../../../../utils/constants";

import { ButtonLoading } from "../../../ButtonLoading";
import { X } from "@phosphor-icons/react";
import { PreviewImagePet } from "./PreviewImagePet";
import { DatePickerCustom } from "../../../DatePickerCustom";
import { FORMAT_DATES } from "../../../../utils/dates";
import dayjs from "dayjs";
import { InputCelNumber } from "../../../InputCelNumber";
import { AdditionalData } from "./AdditionalData";

export const FormRegisterPet = ({ edit = false, data, close }) => {
  const { app, userData } = useContext(CurrentUserContext);
  let { id } = useParams();
  const queryClient = useQueryClient();
  const isEditMode = edit && data;
  const imageInit = isEditMode ? data?.image : "";

  const { showErrorSnackbar, showSuccessSnackbar } = useSnackBars();
  const [isLoading, setisLoading] = useState(false);
  const [previewImg, setPreviewImg] = useState(imageInit);
  const [imageStorage, setImageStorage] = useState();
  const imgDB = getStorage(app);

  const getDefaultValues = () => {
    const birthdate = data?.birthdate ? dayjs(data?.birthdate) : "";
    const lastDateUpdate = dayjs();

    if (isEditMode)
      return {
        ...data,
        birthdate,
        lastDateUpdate,
      };
    return {
      description: defaultDescriptionPets,
      lastDateUpdate,
    };
  };

  const {
    formState: { errors },
    register,
    handleSubmit,
    watch,
    control,
  } = useForm({
    defaultValues: getDefaultValues(),
    mode: "onChange",
  });
  const { name, description } = watch();

  const onSubmit = async (data, e) => {
    const birthdate = data?.birthdate ? getDateToString(data?.birthdate) : "";
    onRegister({
      ...data,
      id,
      uidUser: userData?.uid,
      email: userData?.email,
      birthdate,
    });
  };
  const onError = (errors, e) => console.error(errors);
  const onRegister = (body) => {
    setisLoading(true);

    if (imageStorage) imagesSet(body);
    else setPet(body);
  };

  const imagesSet = async (body) => {
    const imgRef = ref(imgDB, `pictures/pets/${id}/profile/profile.webp`);

    try {
      const data = await uploadBytes(imgRef, imageStorage);
      const downloadURL = await getDownloadURL(data?.ref);
      setPet(body, downloadURL);
      return downloadURL;
    } catch (error) {
      console.error("Error in multiImagesSet:", error);
      throw error;
    }
  };

  const setPet = (body, val) => {
    const bodySet = { ...body, created: true, image: val };

    PetService.setPet({
      id,
      body: bodySet,
    })
      .then(() => {
        showSuccessSnackbar("Mascota registrada!");
        queryClient.invalidateQueries(namesUseQuery?.getPetById);
        edit && close();
      })
      .catch((err) => {
        setisLoading(false);
        console.error(err);
        showErrorSnackbar(err?.response?.data?.message);
      });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      {!edit ? (
        <TitleRegister>Registrar Mascota</TitleRegister>
      ) : (
        <Box display={"flex"} justifyContent={"space-between"}>
          <TitleRegister>Editar Mascota</TitleRegister>
          <IconButton onClick={close}>
            <X size={30} />
          </IconButton>
        </Box>
      )}

      <PreviewImagePet previewImg={previewImg} />

      <Box display={"flex"} justifyContent={"center"}>
        <UploadImage
          previewImg={previewImg}
          setPreviewImg={setPreviewImg}
          setImageStorage={setImageStorage}
          edit={edit}
          id={id}
        />
      </Box>

      <TextFieldBOValidate
        register={register}
        name="name"
        label={"Nombre de la mascota"}
        errors={errors}
        isRequired
        maxLength={30}
        length={name?.length}
      />
      <TextFieldBOValidate
        register={register}
        name="description"
        label={"Descripcion"}
        errors={errors}
        isRequired
        rows={4}
        maxLength={300}
        length={description?.length}
      />
      <Typography>Contacto principal</Typography>
      <InputCelNumber name={"cel"} control={control} />
      <Typography>Contacto secundario</Typography>
      <InputCelNumber name={"tel"} control={control} />
      <Box mt={2}>
        <DatePickerCustom
          control={control}
          dateFormat={FORMAT_DATES?.standard}
          name="birthdate"
          label={"Fecha de nacimiento"}
          dateField
          errors={errors}
          isLoading={isLoading}
        />
      </Box>
      <AdditionalData
        register={register}
        errors={errors}
        watch={watch}
        control={control}
      />
      <Box textAlign={"end"}>
        <ButtonLoading isLoading={isLoading}>
          {edit ? "Guardar Mascota" : "Registrar Mascota"}
        </ButtonLoading>
      </Box>
    </form>
  );
};
