import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { ButtonCustom } from "../../Common.styled";
import { dowloadData } from "../../../utils/functions";
import { menuOptionBO } from "../../../utils/constants";
import { useTableUsers } from "../../../hooks/Users/useTableUsers";

export const TableUsers = ({ menu }) => {
  const { data } = useTableUsers({ menu });

  return (
    <>
      {menu === "all" && (
        <ButtonCustom onClick={() => dowloadData(data, menuOptionBO?.users)}>
          Descargar txt
        </ButtonCustom>
      )}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Fecha</TableCell>
              <TableCell>Email</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.length > 0 ? (
              data?.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.uid}</TableCell>
                  <TableCell>{item.creationDate}</TableCell>
                  <TableCell></TableCell>
                  <TableCell>{item.email}</TableCell>
                </TableRow>
              ))
            ) : (
              <div>Sin resultados</div>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
