import React, { useContext, useState } from "react";
import { CodeArgPhone, TitleRegister } from "../../Pets/Pets.styled";
import { useForm } from "react-hook-form";
import { Box, IconButton } from "@mui/material";
import { X } from "@phosphor-icons/react";
import { TextFieldBOValidate } from "../../../TextFieldBO/TextFieldBOValidate";
import { ButtonLoading } from "../../../ButtonLoading";

import { UploadImage } from "../../../UploadFiles/UploadImage";
import { useParams } from "react-router";
import CurrentUserContext from "../../../../contexts/CurrentUserContext";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from "@firebase/storage";
import {
  defaultDescriptionStores,
  namesUseQuery,
  typeObject,
} from "../../../../utils/constants";
import { useSnackBars } from "../../../../contexts/Snackbar/SnackbarContext";
import { useQueryClient } from "react-query";
import { StoresService } from "../../../../services/StoresService";
import { PreviewImageObject } from "../../Objects/NewObject/PreviewImageObject";
import { AccordionDatosBancarios } from "./AccordionDatosBancarios";
import { AccordionRedesSociales } from "./AccordionRedesSociales";

export const FormRegisterStore = ({ edit = false, data, close }) => {
  const isEditMode = edit && data;
  const { app, userData } = useContext(CurrentUserContext);

  const { showErrorSnackbar, showSuccessSnackbar } = useSnackBars();
  const imageInit = isEditMode ? data?.images?.medium : "";
  const [isLoading, setisLoading] = useState(false);
  const [previewImg, setPreviewImg] = useState(imageInit);
  const [imageStorage, setImageStorage] = useState();
  let { id } = useParams();
  const imgDB = getStorage(app);
  const queryClient = useQueryClient();

  const getDefaultValues = () => {
    if (isEditMode) return data;
    return {
      description: defaultDescriptionStores,
      type: typeObject?.stores,
    };
  };

  const {
    formState: { errors },
    register,
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: getDefaultValues(),
    mode: "onChange",
  });

  const onSubmit = async (data, e) => {
    onRegister({ ...data, id, uidUser: userData?.uid, email: userData?.email });
  };

  const onRegister = (body) => {
    setisLoading(true);

    if (imageStorage?.length > 0) {
      multiImagesSet(body);
    } else {
      setStore(body);
    }
  };

  const multiImagesSet = async (body) => {
    const imgRef = ref(imgDB, `pictures/pets/${id}/profile/profile.webp`);

    try {
      const data = await uploadBytes(imgRef, imageStorage);
      const downloadURL = await getDownloadURL(data?.ref);
      const image = downloadURL;
      setStore(body, image);
      return downloadURL;
    } catch (error) {
      console.error("Error in multiImagesSet:", error);
      throw error;
    }
  };

  const setStore = (body, val) => {
    const bodySet = val
      ? { ...body, created: true, images: val }
      : { ...body, created: true };

    StoresService.setStore({
      id,
      body: bodySet,
      token: userData?.accessToken,
    })
      .then((respuesta) => {
        console.log(respuesta?.data);
        showSuccessSnackbar("Se registro correctamente!");
        queryClient.invalidateQueries(namesUseQuery?.GET_STORE_BY_ID);
        edit && close();
      })
      .catch((err) => {
        setisLoading(false);
        console.error(err);
        showErrorSnackbar(err);
      });
  };

  const onError = (errors, e) => console.log(errors);
  const { name, description, alias, cel, emailMP, instagram, facebook } =
    watch();

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      {!edit ? (
        <TitleRegister>Registrar Tienda</TitleRegister>
      ) : (
        <Box display={"flex"} justifyContent={"space-between"}>
          <TitleRegister>Editar Tienda</TitleRegister>
          <IconButton onClick={close}>
            <X size={30} />
          </IconButton>
        </Box>
      )}

      <PreviewImageObject previewImg={previewImg} />

      <Box display={"flex"} justifyContent={"center"}>
        <UploadImage
          previewImg={previewImg}
          setPreviewImg={setPreviewImg}
          setImageStorage={setImageStorage}
          edit={edit}
          id={id}
        />
      </Box>

      <TextFieldBOValidate
        register={register}
        name="title"
        label={"Nombre de la tienda"}
        errors={errors}
        isRequired
        maxLength={30}
        length={name?.length}
      />
      <TextFieldBOValidate
        register={register}
        name="description"
        label={"Descripcion"}
        errors={errors}
        isRequired
        rows={4}
        maxLength={300}
        length={description?.length}
      />
      <Box display="flex">
        <CodeArgPhone>+54 9</CodeArgPhone>
        <Box sx={{ width: "100%" }}>
          <TextFieldBOValidate
            register={register}
            name="cel"
            label={"Celular (sin 0 ni 15 )"}
            errors={errors}
            type={"number"}
            maxLength={30}
            length={cel?.length}
          />
        </Box>
      </Box>

      <AccordionDatosBancarios
        register={register}
        errors={errors}
        alias={alias}
        emailMP={emailMP}
      />
      <Box mt={2}>
        <AccordionRedesSociales
          errors={errors}
          register={register}
          instagram={instagram}
          facebook={facebook}
        />
      </Box>

      <Box textAlign={"end"}>
        <ButtonLoading isLoading={isLoading}>
          {edit ? "Guardar Tienda" : "Registrar Tienda"}
        </ButtonLoading>
      </Box>
    </form>
  );
};
