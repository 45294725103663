import { getAuth, onAuthStateChanged } from "firebase/auth";
import { createContext, useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { UserService } from "../services/UserService";

const app = initializeApp(JSON.parse(process.env.REACT_APP_BD));

const auth = getAuth(app);

const CurrentUserContext = createContext({});
export function CurrentUserProvider({ children }) {
  const [userData, setUserData] = useState(null);
  const [isLoadingUser, setIsLoadingUser] = useState(true);

  const logOut = () => {
    auth.signOut().then(() => {
      setUserData(null);
      localStorage.removeItem("token");
    });
  };

  const stateChanged = () => {
    onAuthStateChanged(auth, async (user) => {
      const token = user?.accessToken;
      if (token) {
        localStorage.setItem("token", token);
        await UserService.getCurrentUser()
          .then((userData) => {
            setUserData({ ...user, ...userData?.[0] });
          })
          .catch((err) => {
            console.log(err?.message);
            alert(err?.message);
          });
      } else setUserData(null);

      setIsLoadingUser(false);
    });
  };

  useEffect(() => {
    stateChanged();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  return (
    <CurrentUserContext.Provider
      value={{
        userData,
        setUserData,
        logOut,
        app,
        auth,
        isLoadingUser,
        stateChanged,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
}

export default CurrentUserContext;
