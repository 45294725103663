import React, { useState } from "react";
import { ButtonCustom } from "../Common.styled";
import { theme } from "../theme";
import { Box, Dialog, DialogContent, useMediaQuery } from "@mui/material";
import {
  ContainerActionCrop,
  ContainerActionImageForm,
} from "./UploadImage.styled";
import Cropper from "react-easy-crop";
import "./styles.css";
import getCroppedImg from "./cropImage";

export const UploadImage = ({
  setPreviewImg,
  previewImg,
  setImageStorage,
  edit,
}) => {
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setopen] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setcroppedAreaPixels] = useState({});

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgSrc(reader.result?.toString() || "");
        setopen(true);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  const showCroppedImage = async () => {
    const croppedImage = await getCroppedImg(imgSrc, croppedAreaPixels);
    setPreviewImg(croppedImage?.base64);
    setImageStorage(croppedImage?.blob);
  };

  const styleLabel = {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 20,
    color: theme.palette.primary.secondary,
    fontWeight: 700,
    padding: "10px 24px",
    marginTop: theme.spacing(2),
    cursor: "pointer",
    ":focus": {
      backgroundColor: theme.palette.primary.secondary,
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
    },
    ":disabled": {
      backgroundColor: theme.palette.primary.secondary,
    },
  };

  const [zoom, setZoom] = useState(1);
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setcroppedAreaPixels(croppedAreaPixels);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <ContainerActionImageForm
        edit={edit}
        imgSrc={imgSrc}
        previewImg={previewImg}
      >
        <Box item>
          <Box>
            <label style={{ ...styleLabel }} for="files" class="btn">
              {!previewImg
                ? "Subir Imagen"
                : isSmall
                ? "Cambiar"
                : "Cambiar Imagen"}
            </label>
            <input
              id="files"
              onChange={onSelectFile}
              style={{ display: "none" }}
              type="file"
              accept="image/*"
            />
          </Box>
        </Box>

        {previewImg && (!edit || imgSrc) && (
          <Box>
            <ButtonCustom
              onClick={() => setopen(true)}
              sx={{ marginTop: "0px !important" }}
            >
              Editar imagen
            </ButtonCustom>
          </Box>
        )}
      </ContainerActionImageForm>

      <Dialog open={open} maxWidth={"sm"} fullWidth fullScreen={isSmall}>
        <DialogContent sx={{ minHeight: 700 }}>
          <div style={{ height: "400px", position: "relative" }}>
            <div className="App">
              <div className="crop-container">
                <Cropper
                  image={imgSrc}
                  crop={crop}
                  zoom={zoom}
                  aspect={4 / 3}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </div>
              <div className="controls">
                <input
                  type="range"
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e) => {
                    setZoom(e.target.value);
                  }}
                  className="zoom-range"
                />
              </div>
            </div>
          </div>
          <ContainerActionCrop>
            <ButtonCustom invert inv onClick={() => setopen(false)}>
              Cancelar
            </ButtonCustom>
            <ButtonCustom
              onClick={() => {
                showCroppedImage();
                setopen(false);
              }}
            >
              Aceptar
            </ButtonCustom>
          </ContainerActionCrop>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
