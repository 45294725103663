import { useQuery } from "react-query";
import { PetService } from "../../services/PetsService";
import { namesUseQuery } from "../../utils/constants";

export function useGetPetById(id) {
  const { data, isLoading } = useQuery(
    [namesUseQuery?.getPetById, id],
    () => PetService.getPetById(id),
    {
      enabled: true,
      onError: (error) => {
        console.log(error);
      },
    }
  );

  return {
    data: data?.[0],
    isLoading,
  };
}
