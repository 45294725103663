import React, { useContext } from "react";
import CurrentUserContext from "../../../../contexts/CurrentUserContext";
import { ItemListQr } from "./ItemListQr";
import { ListEmpty } from "./ListEmpty";
import { Skeleton, Typography } from "@mui/material";
import { usePetsByEmail } from "../../../../hooks/Pets/usePetsByEmail";
import { theme } from "../../../theme";
import { typeObject } from "../../../../utils/constants";

export const ListQrs = () => {
  const { isLoadingUser } = useContext(CurrentUserContext);

  const { data: list, isLoading } = usePetsByEmail();

  return (
    <>
      {list?.length > 0 ? (
        <>
          <Typography
            sx={{ padding: theme.spacing(0, 3) }}
            variant="h5"
            color={"primary"}
          >
            Mascotas
          </Typography>
          {list?.map((item) => (
            <ItemListQr type={typeObject?.pets} item={item} key={item?.id} />
          ))}
        </>
      ) : isLoadingUser || isLoading ? (
        <>
          <Skeleton height={120} />
          <Skeleton height={120} />
          <Skeleton height={120} />
        </>
      ) : (
        <ListEmpty />
      )}
    </>
  );
};
