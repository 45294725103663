import { useNavigate } from "react-router";
import { ButtonCustom } from "../../Common.styled";
import { ContainerMenuBo } from "../Backoffice.styled";
import { routes } from "../../../utils/paths";

export const MenuBO = () => {
  const navigate = useNavigate();

  return (
    <ContainerMenuBo>
      <ButtonCustom invert onClick={() => navigate(routes.adminUsers)}>
        Usuarios
      </ButtonCustom>
      <br />
      <ButtonCustom invert onClick={() => navigate(routes.adminPet)}>
        Mascotas
      </ButtonCustom>
      {/* <ButtonCustom invert onClick={() => navigate(routes.adminObjects)}>
        Objetos
      </ButtonCustom>
      <ButtonCustom invert onClick={() => navigate(routes.adminStores)}>
        Tiendas
      </ButtonCustom> */}
    </ContainerMenuBo>
  );
};
