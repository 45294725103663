import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import {
  ButtonPet,
  ContainerAge,
  ContainerDescription,
  ContainerPet,
  ContainerTitle,
} from "./Pets.styled";
import "react-lazy-load-image-component/src/effects/blur.css";
import { CaretDown } from "@phosphor-icons/react";
import { ImageProfile } from "./ImageProfile";
import { calculateAge, formatPhoneNumber } from "../../../utils/functions";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { ContactActions } from "./ContactActions";

export const Pet = ({ pet }) => {
  const {
    name,
    cel: mainNumber,
    description,
    email,
    tel: secondaryNumber,
    birthdate,
    raza,
    alimento,
  } = pet || {};

  const getAge = () => {
    if (birthdate) return calculateAge(birthdate);
  };
  const aditionalData = raza || alimento;
  return (
    <ContainerPet disableGutters maxWidth={"sm"}>
      <Box>
        <ImageProfile pet={pet} />

        <ContainerTitle> ¡Hola soy {name}!</ContainerTitle>
        {birthdate && <ContainerAge>Tengo {getAge()}</ContainerAge>}
        <ContainerDescription> {description}</ContainerDescription>
        {mainNumber && (
          <ContactActions
            number={mainNumber}
            name={pet?.name}
            label={"Contacto principal"}
          />
        )}
        {secondaryNumber && (
          <Box mt={2}>
            <ContactActions
              number={secondaryNumber}
              name={pet?.name}
              label={"Contacto secundario"}
            />
          </Box>
        )}

        <Accordion sx={{ marginTop: 2 }}>
          <AccordionSummary
            expandIcon={<CaretDown size={32} />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography fontWeight={700}>Datos de contacto</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ textAlign: "left" }}>
            <Typography fontWeight={700}>
              {mainNumber && (
                <span>
                  Contacto principal:{" "}
                  {formatPhoneNumberIntl(formatPhoneNumber(mainNumber))}
                  <br />
                </span>
              )}

              {secondaryNumber && (
                <span>
                  Contacto secundario:{" "}
                  {formatPhoneNumberIntl(formatPhoneNumber(secondaryNumber))}
                  <br />
                </span>
              )}

              {email && <span> Email: {email}</span>}
            </Typography>
          </AccordionDetails>
        </Accordion>
        {aditionalData && (
          <Accordion sx={{ marginTop: 2 }}>
            <AccordionSummary
              expandIcon={<CaretDown size={32} />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography fontWeight={700}>Datos de adicionales</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ textAlign: "left" }}>
              {raza && (
                <Typography fontWeight={700}>Soy de raza {raza}.</Typography>
              )}
              {alimento && (
                <Typography fontWeight={700}>
                  Me alimento con {alimento}.
                </Typography>
              )}
            </AccordionDetails>
          </Accordion>
        )}
        {email && (
          <Box mt={1} display={"flex"} justifyContent={"center"}>
            <ButtonPet
              onClick={() => window.open(`mailto:${email}`, "_blank")}
              height={"auto"}
              sx={{ height: "auto" }}
            >
              <Typography
                mt={1}
                fontSize={20}
                fontWeight={700}
                sx={{ fontFamily: "Light" }}
              >
                Enviar Email
              </Typography>
            </ButtonPet>
          </Box>
        )}
      </Box>
    </ContainerPet>
  );
};
