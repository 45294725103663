import { useForm } from "react-hook-form";
import { TextFieldBOValidate } from "../../TextFieldBO/TextFieldBOValidate";
import { ContainerButtonSave } from "../../BO/Backoffice.styled";
import { useSnackBars } from "../../../contexts/Snackbar/SnackbarContext";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { errorsSwitch, validateAge } from "../../../utils/functions";
import { heightNavbar } from "../../../utils/constants";
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Skeleton,
} from "@mui/material";
import { FORMAT_DATES } from "../../../utils/dates";
import dayjs from "dayjs";
import { DatePickerCustom } from "../../DatePickerCustom";
import { useContext, useState } from "react";
import CurrentUserContext from "../../../contexts/CurrentUserContext";
import { ButtonLoading } from "../../ButtonLoading";
import { BoxGoogleButton } from "../Login/Login.styled";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { theme } from "../../theme";
import { ButtonGoogleCustom } from "../../ButtonGoogleCustom";
import { UserService } from "../../../services/UserService";

export const Register = ({ onClose }) => {
  const { stateChanged, auth } = useContext(CurrentUserContext);

  const { showErrorSnackbar, showSuccessSnackbar } = useSnackBars();
  const [isLoading, setIsloading] = useState(false);
  const [firstStep, setfirstStep] = useState(true);

  const {
    formState: { errors },
    register,
    handleSubmit,
    control,
    watch,
  } = useForm();
  const { email } = watch();

  const onSubmit = async (data) => {
    if (firstStep) setfirstStep(false);
    else createUser(data);
  };

  const createUser = async (data) => {
    const { email, pass } = data;

    const userInfo = {
      ...data,
      birthdate: dayjs(data?.birthdate).format(FORMAT_DATES?.standard),
    };

    setIsloading(true);
    await createUserWithEmailAndPassword(auth, email, pass)
      .then(async () => {
        await UserService.addUserWithEmail(userInfo);
        stateChanged();

        onClose && onClose();
        showSuccessSnackbar("Usuario creado correctamente!");
      })
      .catch((error) => {
        setIsloading(false);
        console.error(error.code, error.message);
        showErrorSnackbar(errorsSwitch(error.code));
      });
  };

  const onError = (errors, e) => console.log(errors);
  const isValidate = (value) => {
    if (email === value) return true;
    else {
      return "Los emails no coinciden";
    }
  };

  return (
    <div>
      <div>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          {!firstStep && (
            <>
              <TextFieldBOValidate
                register={register}
                name="email"
                label={"Email"}
                errors={errors}
                isRequired
                isEmail
                isLoading={isLoading}
                disabledPaste
              />
              <TextFieldBOValidate
                register={register}
                name="email2"
                label={"Repetir el email"}
                errors={errors}
                isRequired
                extravalidations={isValidate}
                isEmail
                disabledPaste
              />

              <TexfieldPass
                isLoading={isLoading}
                errors={errors}
                register={register}
              />
              <Box>
                <DatePickerCustom
                  control={control}
                  dateFormat={FORMAT_DATES?.standard}
                  name="birthdate"
                  label={"Fecha de nacimiento"}
                  rules={{
                    required: "Fecha de nacimiento requerida",
                    validate: validateAge,
                  }}
                  dateField
                  errors={errors}
                  isLoading={isLoading}
                />
              </Box>
            </>
          )}

          <ContainerButtonSave>
            <ButtonLoading
              isLoading={isLoading}
              type="submit"
              variant="contained"
              style={{ borderRadius: "10px !important", width: "100%" }}
            >
              Registrarme con email
            </ButtonLoading>
          </ContainerButtonSave>
        </form>

        <BoxGoogleButton>
          <ButtonGoogleCustom
            label="Registrarse con Google"
            isLoading={isLoading}
            onClose={onClose}
          />
        </BoxGoogleButton>
      </div>
    </div>
  );
};

export const TexfieldPass = ({ register, errors, isLoading }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const msgErr = errors?.pass;

  return isLoading ? (
    <Skeleton type="rectangular" height={heightNavbar} />
  ) : (
    <FormControl
      sx={{ m: theme.spacing(2, 0), width: "100%" }}
      variant="outlined"
    >
      <InputLabel htmlFor="outlined-adornment-password">
        Nueva contraseña
      </InputLabel>
      <OutlinedInput
        {...register("pass", {
          required: "Este campo es requerido",
          validate: (value) => {
            if (value.length < 6) {
              return `La contraseña debe tener al menos 6 caracteres`;
            }
            return true;
          },
        })}
        id="outlined-adornment-password"
        type={showPassword ? "text" : "password"}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label="Nueva contraseña"
      />
      {msgErr && (
        <p
          className="error"
          style={{ color: "red", textAlign: "start", marginTop: 0 }}
        >
          * {msgErr?.message}
        </p>
      )}
    </FormControl>
  );
};
