import { Box, Grid, Paper, Typography } from "@mui/material";
import {
  ContainerDescription,
  ContainerItem,
  ContainerItemLink,
  ContainerItemList,
} from "./ListQrs.styled";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { PawPrint } from "@phosphor-icons/react";
import { typeObject } from "../../../../utils/constants";

export const ItemListQr = ({ item, type }) => {
  const { id, name, image, description, title } = item || {};

  return (
    <ContainerItemLink to={`/${typeObject[type]}/` + id}>
      <Paper elevation={5} sx={{ borderRadius: 10, margin: 2 }}>
        <ContainerItemList>
          <ContainerItem key={id + type}>
            <Box textAlign={"center"} alignSelf={"center"} minWidth={150}>
              {image ? (
                <LazyLoadImage
                  alt={"Imagen de perfil de" + name}
                  effect="blur"
                  src={image}
                  style={{
                    width: 150,
                    borderRadius: 20,
                    aspectRatio: "4/3",
                  }}
                  onLoad={() => console.log("onLoad")}
                  threshold={100}
                />
              ) : (
                <PawPrint size={100} />
              )}
            </Box>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Typography textAlign="center" variant="h4" color="primary">
                  {name || title}
                </Typography>
                <ContainerDescription textAlign={"start"}>
                  <Typography variant="h8" color="primary">
                    {description}
                  </Typography>
                </ContainerDescription>
              </Grid>
            </Grid>
          </ContainerItem>
        </ContainerItemList>
      </Paper>
    </ContainerItemLink>
  );
};
