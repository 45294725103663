import { useState } from "react";
import { FILTERS_USER_BO } from "../../../utils/constants";
import { TableUsers } from "./TableUsers";
import { useCountUsers } from "../../../hooks/Users/useCountUsers";
import { ButtonCustom } from "../../Common.styled";

export const UserBo = () => {
  // const [documentos, setDocumentos] = useState([]);
  const [menu, setMenu] = useState(FILTERS_USER_BO?.today);

  const { data } = useCountUsers();
  return (
    <div>
      <h1>Usuarios</h1>
      <h2>Cantidad de usuarios totales: {data?.created}</h2>
      <ButtonCustom onClick={() => setMenu(FILTERS_USER_BO?.all)}>
        Mostrar todos
      </ButtonCustom>
      <ButtonCustom onClick={() => setMenu(FILTERS_USER_BO?.yesterday)}>
        Ayer
      </ButtonCustom>
      <ButtonCustom onClick={() => setMenu(FILTERS_USER_BO?.today)}>
        Hoy
      </ButtonCustom>

      <TableUsers menu={menu} />
    </div>
  );
};
