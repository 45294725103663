import { useQuery } from "react-query";
import { namesUseQuery } from "../../utils/constants";
import { UserService } from "../../services/UserService";
import { useSnackBars } from "../../contexts/Snackbar/SnackbarContext";

export function useTableUsers(params) {
  const { showErrorSnackbar } = useSnackBars();

  const { data, isLoading } = useQuery(
    [namesUseQuery?.USE_TABLE_USERS, params],
    () => UserService.getUsers(params),
    {
      onError: (error) => {
        console.log(error);
        showErrorSnackbar(error?.response?.data?.body);
      },
    }
  );

  return {
    data: data,
    isLoading,
  };
}
