import React, { useContext, useState } from "react";
import { TextFieldBOValidate } from "../../TextFieldBO/TextFieldBOValidate";
import { ContainerButtonSave } from "../../BO/Backoffice.styled";
import { useForm } from "react-hook-form";
import { signInWithEmailAndPassword } from "firebase/auth";
import CurrentUserContext from "../../../contexts/CurrentUserContext";
import { useSnackBars } from "../../../contexts/Snackbar/SnackbarContext";
import { errorsSwitch } from "../../../utils/functions";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { theme } from "../../theme";
import { ButtonLoading } from "../../ButtonLoading";
import { UserService } from "../../../services/UserService";

export const FormLogin = ({ onClose }) => {
  const { auth, stateChanged } = useContext(CurrentUserContext);
  const [firstStep, setfirstStep] = useState(true);

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsloading] = useState(false);

  const { showErrorSnackbar } = useSnackBars();
  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm();

  const onSubmit = async (data, e) => {
    if (firstStep) {
      setfirstStep(false);
    } else {
      signIn(data, e);
    }
  };

  const signIn = async (data, e) => {
    const { email, pass } = data;
    setIsloading(true);
    await signInWithEmailAndPassword(auth, email, pass)
      .then(async () => {
        await UserService.addUser();
        stateChanged();

        setIsloading(false);
        onClose && onClose();
      })
      .catch((error) => {
        setIsloading(false);
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(errorCode, errorMessage);
        showErrorSnackbar(errorsSwitch(error.code));
      });
  };
  const onError = (errors, e) => console.log(errors);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <TextFieldBOValidate
        register={register}
        name="email"
        label={"Dirección de correo electrónico"}
        errors={errors}
        style={{ marginTop: "0px !important" }}
      />
      {!firstStep && (
        <FormControl
          sx={{ m: theme.spacing(2, 0), width: "100%" }}
          variant="outlined"
        >
          <InputLabel htmlFor="outlined-adornment-password">
            Contraseña
          </InputLabel>
          <OutlinedInput
            {...register("pass", {
              required: true,
            })}
            id="outlined-adornment-password"
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Contraseña"
          />
        </FormControl>
      )}

      <ContainerButtonSave>
        <ButtonLoading
          style={{ borderRadius: "10px !important", width: "100%" }}
          isLoading={isLoading}
          type="submit"
          variant="contained"
        >
          {firstStep ? "Continuar" : "Iniciar Sesión"}
        </ButtonLoading>
      </ContainerButtonSave>
    </form>
  );
};
