import { useQuery } from "react-query";
import { namesUseQuery } from "../../utils/constants";
import { PetService } from "../../services/PetsService";
import { useContext } from "react";
import CurrentUserContext from "../../contexts/CurrentUserContext";

export function usePetsByEmail() {
  const { userData } = useContext(CurrentUserContext);

  const { data, isLoading } = useQuery(
    [namesUseQuery?.PETS_BY_EMAIL],
    () => PetService.petsByUser(),
    {
      enabled: !!userData,
      onError: (error) => {
        console.log(error);
      },
    }
  );
  return {
    data,
    isLoading,
  };
}
