import { Paper, Typography } from "@mui/material";
import { ButtonWhatsapp } from "../ButtonWhatsapp/ButtonWhatsapp";

export const NotFound = () => {
  return (
    <div>
      <Paper sx={{ padding: 3, minHeight: 500 }}>
        <Typography fontWeight={700} mb={1} fontSize={20}>
          404
        </Typography>
        <Typography fontWeight={700} mb={2} fontSize={30}>
          La página que intentas acceder no existe.
        </Typography>
        <Typography>
          Si crees que esto es un error, por favor, refresca la página o
          contacta con soporte.
        </Typography>
        <Typography mt={1}>
          ¿Encontraste una mascota? Contáctanos y te responderemos a la
          brevedad.
        </Typography>

        <Typography fontWeight={700} mt={4} fontSize={20}>
          Contacto de soporte{" "}
        </Typography>
        <ButtonWhatsapp
          text={"Estoy intentando acceder a una pagina que no existe! "}
          cel={"5491170835175"}
        />
      </Paper>
    </div>
  );
};
