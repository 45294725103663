import { Box } from "@mui/material";
import React, { useContext } from "react";
import { BoxCard } from "./Pets.styled";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { PawPrint } from "@phosphor-icons/react";
import { EditPet } from "./EditPet/EditPet";
import { ModalViewQr } from "../../ModalViewQr/ModalViewQr";
import { getUrlPetQr } from "../../../utils/functions";
import CurrentUserContext from "../../../contexts/CurrentUserContext";
import { useParams } from "react-router-dom";

export const ImageProfile = ({ pet }) => {
  let { id } = useParams();
  const { userData } = useContext(CurrentUserContext);

  const owner = userData?.email === pet?.email;
  const { name, image } = pet || {};
  return (
    <Box sx={{ position: "relative" }}>
      {image ? (
        <BoxCard sx={{ clipPath: "ellipse(90% 83% at 50% 15%)" }}>
          <LazyLoadImage
            alt={"Imagen de perfil de" + name}
            effect="blur"
            src={image}
            width={"100%"}
            style={{
              objectFit: "cover",
              aspectRatio: "4/3",
              minHeight: 300,
            }}
          />
        </BoxCard>
      ) : (
        <Box>
          <PawPrint size={200} />
        </Box>
      )}
      {owner && <EditPet data={pet} />}
      <ModalViewQr name={name} url={getUrlPetQr(id)} />
    </Box>
  );
};
