import { request } from "./axios";
let keyApi = "pets-1.0";

export class Pet {
  getPetById(id) {
    return request({ url: `${keyApi}/petByid/${id}`, method: "get" });
  }
  setPet({ body }) {
    return request({ url: `${keyApi}/updatePet/`, method: "put", data: body });
  }
  generateQR() {
    return request({
      url: `${keyApi}/create-emtpy-petSql`,
      method: "post",
      data: {},
    });
  }
  petsByUser() {
    return request({
      url: `${keyApi}/petsByUser`,
      method: "put",
      data: {},
    });
  }

  petsByEmail(email) {
    return request({
      url: `${keyApi}/petsByEmail`,
      method: "put",
      data: { email },
    });
  }

  getAllPets = async () => {
    return request({
      url: `${keyApi}/allpets`,
      method: "get",
      data: {},
    });
  };

  getCountPetsRegisters = async () => {
    return request({
      url: `${keyApi}/contadorPets/`,
      method: "get",
    });
  };
}

export const PetService = new Pet();
