import { Box, Typography } from "@mui/material";
import React from "react";
import { theme } from "../theme";

export const TitleNavbar = ({ width = 130, height = 60 }) => {
  const entorno = process.env.REACT_APP_ENTORNO;

  return (
    <Box display={"flex"} alignItems={"center"} ml={3}>
      <Box display={"flex"}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 -10 120 50"
        >
          <text
            id="BUSCAQR"
            x="0"
            y="38"
            style={{
              stroke: "#fff",
              strokeLinejoin: "round",
              strokeWidth: "1px",
              fontSize: "60px", // Ajustado para que se vea bien
              fill: "#fff",
              fontFamily: "Light",
            }}
            transform="scale(0.447 0.631)"
          >
            BUSCAQR
          </text>
        </svg>
      </Box>

      <Typography
        color={theme.palette.primary.secondary}
        sx={{
          alignSelf: "center",
          marginLeft: "4px",
          height: "35px",
          alignContent: "end",
        }}
      >
        {entorno}
      </Typography>
      {console.log(entorno)}
    </Box>
  );
};
