import { useQuery } from "react-query";
import { namesUseQuery } from "../../utils/constants";
import { UserService } from "../../services/UserService";

export function useCountUsers() {
  const { data, isLoading } = useQuery(
    [namesUseQuery?.GET_COUNT_BY_USERS],
    () => UserService.getCountUsersRegisters(),
    {
      onError: (error) => {
        console.log(error);
      },
    }
  );

  return {
    data: data,
    isLoading,
  };
}
