import axios from "axios";

const client = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

export const request = ({ ...options }) => {
  client.defaults.headers.common.Authorization = localStorage.getItem("token");
  const onSuccess = (response) => {
    return response?.data?.body;
  };

  const onError = (error) => {
    if (error.message === "Network Error") {
      // Manejo personalizado para errores de conexión
      console.error("Error de conexión: no se pudo contactar al servidor.");
      // Puedes retornar un mensaje personalizado o manejar el error de alguna otra manera
      return Promise.reject({
        message:
          "No se pudo conectar al servidor. Verifica tu conexión a internet.",
      });
    }
    return Promise.reject(error);
  };

  return client(options).then(onSuccess).catch(onError);
};
