export const routes = {
  main: process.env.REACT_APP_URL,
  home: "/",
  login: "/login",
  register: "/register",
  adminUsers: "/admin/users",
  adminPet: "/admin/pets",
  adminObjects: "/admin/objects",
  adminStores: "/admin/stores",
  pets: "/pets",
  objects: "/objects",
  stores: "/stores",
  myqrs: "/my-qrs",
  profile: "/perfil",
  working: "/como_funciona",
  instagram: "https://www.instagram.com/buscaqr/",
  facebook: "https://www.facebook.com/share/NHuLpzkfUA6YvNPG/?mibextid=qi2Omg",
  youtube: "https://www.youtube.com/channel/UCOOTX66A0VuwIFbke_qCsxA",
  twitter: "https://twitter.com/BuscaQR",
  whatsapp: `https://api.whatsapp.com/send/?phone=5491170835175&text=Hola%20Busca%20QR,%20quiero%20mi%20QR!`,
  tikTok: "https://www.tiktok.com/@buscaqr",
};
