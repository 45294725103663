import { request } from "./axios";
let keyApi = "user";

export class User {
  getCurrentUser() {
    return request({ url: `${keyApi}/currentUser`, method: "get" });
  }
  addUser() {
    return request({ url: `${keyApi}/loginUser/`, method: "put" });
  }
  addUserWithEmail(body) {
    return request({
      url: `${keyApi}/addWithEmail/`,
      method: "put",
      data: body,
    });
  }
  getCountUsersRegisters = async () => {
    return request({
      url: `${keyApi}/contadorUsers/`,
      method: "get",
    });
  };
  getUsers = async (params) => {
    return request({
      url: `${keyApi}/allUsers/`,
      method: "put",
      data: params,
    });
  };
}

export const UserService = new User();
