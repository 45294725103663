import { useParams } from "react-router-dom";
import { NotFound } from "../../404NotFound/NotFound";
import { getStringToBoolean } from "../../../utils/functions";
import { NewQr } from "../../NewQr/NewQr";
import { useGetStoreById } from "../../../hooks/Store/useGetStoreById";
import { Store } from "./Store";
import { typeObject } from "../../../utils/constants";
import { Box, CircularProgress } from "@mui/material";

const ProfileStore = () => {
  let { id } = useParams();
  const { data, isLoading } = useGetStoreById(id);
  const notFount = !isLoading && !data;
  const created = getStringToBoolean(data?.created);

  if (isLoading)
    return (
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        minHeight={400}
      >
        <CircularProgress size={100} mt={10} />
      </Box>
    );

  if (notFount) return <NotFound />;

  if (created) return <Store data={data} />;

  if (!created) return <NewQr type={typeObject?.stores} />;
};

export default ProfileStore;
