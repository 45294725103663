import { TextFieldBO } from "./TextFieldBOValidate.styled";
import validator from "validator";
import { Skeleton, Typography } from "@mui/material";

export const TextFieldBOValidate = ({
  register,
  isUrl,
  name,
  errors,
  label,
  type,
  isRequired,
  rows = 1,
  maxLength = false,
  length,
  isEmail,
  isLoading,
  readOnly = false,
  extravalidations,
  disabledPaste,
  style,
}) => {
  const msgErr = errors?.[name];

  const handleChange = (e) => {
    e.preventDefault();
  };
  return (
    <>
      {isLoading ? (
        <Skeleton type="text" height={80} />
      ) : (
        <>
          <TextFieldBO
            {...register(name, {
              required: isRequired ? "Este campo es requerido" : false,
              validate: (value) => {
                if (isUrl && !validator.isURL(value)) {
                  if (value === "" && !isRequired) return true;
                  return "Introduzca una URL válida";
                }
                if (isEmail && !validator.isEmail(value)) {
                  if (value === "" && !isRequired) return true;
                  return "El formato de mail no es correcto";
                }

                if (maxLength && value?.length > maxLength) {
                  return `El tamaño máximo permitido es ${maxLength} caracteres`;
                }
                if (extravalidations) return extravalidations(value);
                return true;
              },
            })}
            id="outlined-basic"
            label={label}
            variant="outlined"
            fullWidth
            InputProps={{
              readOnly: readOnly,
            }}
            error={msgErr}
            type={type}
            rows={rows}
            multiline={rows > 1 ? true : false}
            onCut={disabledPaste && handleChange}
            onCopy={disabledPaste && handleChange}
            onPaste={disabledPaste && handleChange}
            sx={{ ...style }}
          />

          {length > -1 && maxLength && (
            <Typography
              textAlign={"end"}
            >{`${length}/${maxLength}`}</Typography>
          )}
          {msgErr && (
            <p
              className="error"
              style={{ color: "red", textAlign: "start", marginTop: 0 }}
            >
              * {msgErr?.message}
            </p>
          )}
        </>
      )}
    </>
  );
};
