import { useParams } from "react-router-dom";
import { NotFound } from "../../404NotFound/NotFound";
import { Pet } from "./Pet";
import { useGetPetById } from "../../../hooks/Pets/useGetPetById";
import { getStringToBoolean } from "../../../utils/functions";
import { SkeletonPet } from "./SkeletonPet";
import { NewQr } from "../../NewQr/NewQr";

const ProfilePet = () => {
  let { id } = useParams();
  const { data: petById, isLoading } = useGetPetById(id);
  const notFount = !isLoading && !petById;
  const created = getStringToBoolean(petById?.created);

  if (isLoading) return <SkeletonPet />;

  if (notFount) return <NotFound />;

  if (created) return <Pet pet={petById} />;

  if (!created) return <NewQr />;
};

export default ProfilePet;
