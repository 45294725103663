import React from "react";
import { ContainerInfo, ContainerPhoto } from "./Profile.styled";
import { Avatar, Grid, TextField, Typography } from "@mui/material";
import { theme } from "../../theme";
import { getInitials } from "../../../utils/functions";
import { ValidateEmail } from "../../ValidateEmail/ValidateEmail";
import { usePetsByEmail } from "../../../hooks/Pets/usePetsByEmail";

export const FormProfile = ({ userData }) => {
  const { displayName, photoURL, emailVerified, email } = userData || {};
  const { data: list } = usePetsByEmail();

  return (
    <>
      <ContainerPhoto>
        <Avatar
          sx={{
            width: "30vw",
            height: "30vw",
            maxHeight: 300,
            maxWidth: 300,
            fontSize: 100,
            [theme.breakpoints.down("md")]: { fontSize: "10vw" },
          }}
          src={photoURL}
        >
          {displayName && getInitials(displayName)}
        </Avatar>
      </ContainerPhoto>

      <ContainerInfo>
        <Typography mt={2} variant="h5" color={"primary"}>
          Cantidad de QRs activos: {list?.length}
        </Typography>
        <Grid container justifyContent={"center"} mt={2} mb={2}>
          <Grid item xs={8}>
            <TextField
              id="filled-basic"
              label="Email"
              InputProps={{
                readOnly: true,
              }}
              value={email}
              variant="filled"
              sx={{ width: "100%" }}
            />
          </Grid>
        </Grid>
        {!emailVerified && <ValidateEmail />}
      </ContainerInfo>
    </>
  );
};
