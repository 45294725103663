import { useQuery } from "react-query";
import { PetService } from "../../services/PetsService";
import { namesUseQuery } from "../../utils/constants";
import { useSnackBars } from "../../contexts/Snackbar/SnackbarContext";

export function useAllPets() {
  const { showErrorSnackbar } = useSnackBars();

  const { data, isLoading } = useQuery(
    [namesUseQuery?.ALL_PETS],
    () => PetService.getAllPets(),
    {
      refetchOnWindowFocus: false,
      onError: (error) => {
        console.log(error);
        showErrorSnackbar(error?.response?.data?.body);
      },
    }
  );

  return {
    data: data,
    isLoading,
  };
}
