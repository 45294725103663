import axios from "axios";
import { getHeader } from "../utils/functions";
import { collection, getDocs } from "@firebase/firestore";
import { typeObject } from "../utils/constants";

export class Object {
  constructor() {
    this.axios = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}objects`, // URL base de la API
    });
  }

  getObjectById(id) {
    return this.axios.get(`/${id}`);
  }
  setObject({ id, body, token }) {
    return this.axios.put(`/edit/${id}`, body, getHeader(token));
  }

  getAll = async () => {
    try {
      const querySnapshot = await getDocs(collection(typeObject?.objects));
      return querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    } catch (error) {
      console.error("Error al obtener documentos:", error);
    }
  };
}

export const ObjectsService = new Object();
