import { useParams } from "react-router-dom";
import { NotFound } from "../../404NotFound/NotFound";
import { getStringToBoolean } from "../../../utils/functions";
import { NewQr } from "../../NewQr/NewQr";
import { Object } from "./Object";
import { SkeletonPet } from "../Pets/SkeletonPet";
import { useGetObjectById } from "../../../hooks/Object/useGetObjectById";
import { typeObject } from "../../../utils/constants";

const ProfileObject = () => {
  let { id } = useParams();
  const { data, isLoading } = useGetObjectById(id);
  const notFount = !isLoading && !data;
  const created = getStringToBoolean(data?.created);

  if (isLoading) return <SkeletonPet />;

  if (notFount) return <NotFound />;

  if (created) return <Object data={data} />;

  if (!created) return <NewQr type={typeObject?.objects} />;
};

export default ProfileObject;
